<template>
    <UiDropdown class="relative text-left">
        <template #trigger>
            <button
                type="button"
                class="group w-full bg-gray-100 bg-opacity-75 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                aria-expanded="false"
                aria-haspopup="true"
            >
                <span class="flex items-center justify-between w-full">
                    <span class="flex items-center justify-between min-w-0 space-x-3">
                        <div
                            class="w-10 h-10 bg-gray-300 bg-center bg-cover rounded-full shrink-0"
                            :style="{ 'background-image': 'url('+$creche.logo+')' }"
                        />
                        <span class="flex flex-col flex-1 min-w-0">
                            <span class="text-sm font-medium text-gray-900 truncate">{{ $creche.name }}</span>
                            <span class="text-xs text-gray-500 truncate">{{ $creche.denomination }}</span>
                        </span>
                    </span>
                    <ChevronUpDownIconMini
                        v-if="$user?.creches && $user.creches.length > 1"
                        class="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500"
                    />
                </span>
            </button>
        </template>
        <template #content>
            <div
                v-if="$user?.creches"
                class="absolute bottom-0 w-full mb-16 dropdown-menu"
            >
                <div v-if="$user.creches.length > 5">
                    <input
                        v-model="search"
                        placeholder="Rechercher..."
                        type="search"
                        name="search"
                        class="border-gray-100 px-3 py-1 text-sm rounded-md w-full"
                        autofocus
                    >
                </div>
                <div class="max-h-96 overflow-auto">
                    <button
                        v-for="creche in filteredCreches"
                        :key="creche.uuid"
                        class="dropdown-menu-item"
                        @click="setCreche(creche)"
                    >
                        <div class="flex items-center space-x-2">
                            <UiAvatar
                                class="w-5 h-5"
                                :path="creche.logo"
                            />
                            <span class="truncate">{{ creche.name }}</span>
                        </div>
                    </button>
                </div>
            </div>
        </template>
    </UiDropdown>
</template>

<script>
import { loadingMixin, searchMixin } from 'abcreche-ui'
import UserRepository from '~/repositories/Creche/UserRepository'

export default {
    mixins: [loadingMixin, searchMixin],

    data () {
        return {
            search: ''
        }
    },

    computed: {
        filteredCreches () {
            return this.$user.creches
                .filter((c) => {
                    return this.searchHasResult([
                        c.name,
                        c.street,
                        c.city,
                        c.postal_code
                    ], this.search)
                })
                .sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
        },
        currentCreche () {
            if (this.$user?.creches) {
                return this.$user.creches.find(c => c.uuid === this.$user.current_creche_uuid)
            } else {
                return {}
            }
        }
    },

    methods: {
        setCreche (creche) {
            const formData = {
                ...this.$user,
                current_creche_uuid: creche.uuid
            }
            this.$dialog.show({
                title: 'Vous êtes sur le point de charger les informations de la creche ' + creche.name,
                buttons: [
                    {
                        title: 'Continuer',
                        class: 'btn-primary',
                        handler: () => {
                            UserRepository.update(formData)
                                .then(() => {
                                    window.location = '/'
                                })
                                .catch(error => this.pageError(error))
                        }
                    },
                    { title: 'Annuler' }
                ]
            })
        }
    }
}
</script>
